@import "../../assets/less/variables.less";

.homepage-wrapper {
  .banner-section {
    .bannerbg-image {
      width: 100vw;
      height: 100%;
      position: absolute;
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    .banner-row {
      height: calc(100vh - 20px);
      min-height: 700px;
      align-items: center;
      text-align: center;

      @media screen and (max-width:767px) {
        height: auto;
        padding-top: 90px;
      }

      h1 {
        font-size: 4rem;
        color: @white;
        font-weight: 800;
        margin-bottom: 1rem;
        margin-top: 3rem;

        span {
          font-weight: 300;
        }

        @media screen and (max-width: 991px) {
          font-size: 3rem;
          line-height: 1.3;
        }

        @media screen and (max-width: 576px) {
          font-size: 2.5rem;
        }
      }

      .build-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
        .icon {
          width: 95px;
          margin-left: 10px;
        }
      }

      p {
        font-size: 25px;
        margin-bottom: 3rem;
        color: @white;
      }

      .start-btn {
        margin-bottom: -20vh;
        margin-top: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .icon {
          fill: @white;
          width: 14px;
        }

        .start-circle {
          width: 98px;
          height: 98px;
          border-radius: 98px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(#f03733, 0.2);
          .start-circle-inner {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(#cc101d, 0.4);
          }
        }
      }

      .ant-btn {
        @media screen and (max-width: 560px) {
          margin: 0 0 2rem;
        }
      }
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.5rem;
      margin: 6.5rem auto 2rem;
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        max-width: 350px;
        margin: auto;
        width: 100%;
        .statics-card {
          background: @background-secondary;
          border-radius: 8px;
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.17);
          padding: 2.2rem 2rem;
          display: flex;
          align-items: center;
          text-align: left;
          .left-icon {
            margin-right: 1.5rem;
            .icon {
              width: 60px;
              height: 48px;
              display: block;
              fill: @white;
            }
          }
          .right-dlt {
            p {
              font-size: 0.875rem;
              margin-bottom: 0;
              font-weight: 400;
            }
            h2 {
              font-weight: 600;
              font-size: 1.8rem;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .statics-section {
    padding: 6rem 0;
    ul {
      list-style-type: none;
      padding-left: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.5rem;
      margin-top: 2.4rem;
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        .statics-card {
          background: @background-secondary;
          border-radius: 8px;
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.17);
          padding: 1.5rem;
          display: flex;
          align-items: center;
          .left-icon {
            margin-right: 1.5rem;
            .icon {
              width: 50px;
              fill: @white;
            }
          }
          .right-dlt {
            p {
              font-size: 0.875rem;
              margin-bottom: 0;
              font-weight: 400;
            }
            h2 {
              font-weight: 600;
              font-size: 1.8rem;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .about-section {
    padding: 6.5rem 0 15rem 0;
    @media screen and (max-width: 767px) {
      padding: 6rem 0;
    }
    .about-image {
      img {
        width: 100%;
        max-width: 1000px;
        @media screen and (max-width: 1100px) {
          max-width: 800px;
        }
        @media screen and (max-width: 991px) {
          max-width: 70%;
        }
      }
    }
    .about-inner {
      position: relative;
      text-align: center;
      .about-card {
        position: absolute;
        max-width: 260px;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 1100px) {
          max-width: 200px;
        }
        @media screen and (max-width: 767px) {
          position: initial;
          max-width: 100%;
          margin-top: 1.5rem;
        }
        h4 {
          font-weight: 700;
          font-size: 1rem;
          @media screen and (max-width: 1100px) {
            font-size: @font-size-base;
          }
          @media screen and (max-width: 991px) {
            font-size: @font-size-base - 1;
          }
        }
        p {
          font-size: @font-size-sm;
          font-weight: 400;
          line-height: 2;
          @media screen and (max-width: 1100px) {
            font-size: @font-size-sm - 1;
          }
          @media screen and (max-width: 991px) {
            font-size: @font-size-sm - 2;
          }
        }
        &.about-card1 {
          left: 2%;
          top: 20%;
          .line-icon {
            text-align: right;
            @media screen and (max-width: 800px) {
              display: none;
            }
            .icon {
              width: 80px;
              @media screen and (max-width: 1100px) {
                width: 50px;
              }
              @media screen and (max-width: 991px) {
                width: 30px;
              }
            }
          }
        }
        &.about-card2 {
          left: 30%;
          bottom: -30%;
          .line-icon {
            text-align: left;
            @media screen and (max-width: 800px) {
              display: none;
            }
            .icon {
              width: 50px;
              @media screen and (max-width: 1100px) {
                width: 30px;
              }
              @media screen and (max-width: 991px) {
                width: 15px;
              }
            }
          }
        }
        &.about-card3 {
          right: 0;
          top: 25%;
          .line-icon {
            text-align: left;
            @media screen and (max-width: 800px) {
              display: none;
            }
            .icon {
              width: 80px;
              @media screen and (max-width: 1100px) {
                width: 50px;
              }
              @media screen and (max-width: 991px) {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .interchain-section {
    padding: 8rem 0 6rem 0;
    background: @background-secondary;
    .interchain-image {
      text-align: center;
      margin-top: 3rem;
      img {
        width: 100%;
        max-width: 880px;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      padding-left: 0;
      margin-top: 1.5rem;
      @media screen and (max-width: 576px) {
        display: block;
        text-align: center;
      }
      li {
        padding-top: 1.5rem;
        div {
          text-align: center;
          h1 {
            font-size: 3.5rem;
            font-weight: 600;
            margin-bottom: 0;
            @media screen and (max-width: 991px) {
              font-size: 2.5rem;
            }
          }
          p {
            font-size: 1.375rem;
            font-weight: 600;
            @media screen and (max-width: 991px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.main-heading {
  text-align: center;
  h2 {
    text-transform: uppercase;
    font-size: 1.875rem;
    @media screen and (max-width:576px) {
      font-size: 1.5rem;
    }
  }
  .ant-divider {
    width: 100px;
    max-width: 100px;
    min-width: auto;
    margin: 1rem auto;
    background: @primary-color;
    height: 8px;
    border-radius: 8px;
  }
  p {
    max-width: 700px;
    margin: auto;
  }
}

.tooltipintro {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: rgba(13, 17, 26, 0.65);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 15px;
      border: 1px solid #3f5477;

      .tooltip-wrapper {
        h3 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
        }

        .tags {
          height: 19px;
          line-height: 19px;
          padding: 0 15px;
          font-size: 10px;
          text-transform: uppercase;
          color: @white;
          display: inline-block;

          &.live {
            background: linear-gradient(
              94.77deg,
              #fe4350 -106.7%,
              #fe4350 162.99%
            );
          }

          &.devnet {
            background: #1e7bf1;
          }

          &.testnet {
            background: #fa550c;
          }

          &.comingsoon {
            background: #184cab;
          }
        }
      }
    }

    .ant-tooltip-arrow {
      bottom: -6px;

      .ant-tooltip-arrow-content {
        background: rgba(13, 17, 26, 0.65);
        width: 14px;
        height: 18px;
        border: 1px solid #3f5477;

        &::before {
          display: none;
        }
      }
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

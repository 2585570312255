@import "../../assets/less/variables.less";

.sidebar{
    background: @sidebar-bg;
    width: 100%;
    max-width: 300px;
    height: calc(100vh - 60px);
}

.footer-main{
    .footer-col{
        padding-top: 2rem;
    }

    .ant-divider{
        margin: 0;
        border-color: rgba(@white, 0.07);
    }
  
    .footerleft-col{
        padding-bottom: 1.2rem;
        @media screen and (max-width:576px) {
            text-align: center;
        }
        .icon, img{
            width: 120px;
        }
        p{
            font-size: @font-size-base;
            font-weight: 300;
            color: @white;
        }
    }
    .footerright-col{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: @white;
        padding-bottom: 1.2rem;
        @media screen and (max-width:576px) {
            justify-content: center;
            margin-bottom: 1.5rem;
        }
        .icon{
            width: 120px;
            margin-left: 1rem;
        }
    }
    h3{
        font-weight: 700;
        font-size: @font-size-lg;
    }
    ul{
        list-style-type: none;
        padding-left: 0;
        li{
            display: block;
            .ant-btn-link{
                font-size: @font-size-base;
                font-weight: 300;
                color: @white;
                margin-bottom: 5px;
            }
        }
    }
    .footerbottom-left{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-top: 1.5rem;
        color: @white;
        .terms-link{
            border: 0;
            padding: 0;
        }
        @media screen and (max-width:576px) {
            text-align: center;
            margin-top: 2rem;
        }
        p{
            color: @white;
            margin-bottom: 0;
            font-size: @font-size-sm;
            font-weight: 300;
        }
        a{
            color: @white;
            font-size: @font-size-sm;
        }
    }
    .footerbottom-right{
        text-align: right;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-top: 1.5rem;
        @media screen and (max-width:576px) {
            text-align: center;
        }
        .footer-links{
            a{
                padding: 8px;
                .icon{
                    fill: @white;
                    width: 18px;
                    height: 16px;
                }
            }
        }
    }
}
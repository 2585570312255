@import "../../assets/less/variables.less";

.header-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 1rem 0;
    .logo{
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        a{
            display: flex;
            align-items: center;
        }
        img {
            width: 140px;
            @media screen and (max-width:490px) {
                width: 80px;
            }
        }
        .icon{
            width: 120px;
            fill: @white;
            @media screen and (max-width:490px) {
                width: 80px;
            }
        }
    }
    .left-menu{
        width: 33.33%;
        @media screen and (max-width:490px) {
            display: flex;
        }
        .ant-btn {
            color: @white;
            font-size: @font-size-base + 1;
            background: none !important;
            text-transform: uppercase;
            padding: 0;
            font-weight: 700;
            border: 0;
            letter-spacing: 1px;
            @media screen and (max-width:490px) {
                font-size: @font-size-base - 2;   
                max-width: 80px;
                white-space: break-spaces;
                line-height: 1.4;
                text-align: left;
            }
        }
    }
    .right-btn{
        width: 33.33%;
        text-align: right;
        .ant-btn{
            @media screen and (max-width:767px) {
                padding: 0 1rem;
                letter-spacing: 1px;
                font-size: @font-size-sm;
            }
            @media screen and (max-width:567px) {
                font-size: @font-size-sm - 1;
                letter-spacing: 0;
            }
            @media screen and (max-width:490px) {
                padding: 0 0.5rem;
                font-size: @font-size-sm - 1;
                height: 30px;
                line-height: 30px;
            }
        }
    }
    .menu-icon{
        width: 25;
        .icon{
            fill: @white;
            width: 20px;
            cursor: pointer;
        }
    }
}

.mobilemenu{
    padding: 20px;
    min-width: 50vw;
    .ant-menu{
        line-height: 1.5;
        padding: 1rem 0;
        li{
            padding: 10px;
        }
    }
}

.mobile-drawer{
    .ant-drawer-content-wrapper{
        .ant-drawer-content{
            background: @primary-color;
            .ant-drawer-body{
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .ant-drawer-header{
                display: none;
            }
            .ant-menu{
                background: none;
                border: 0;
                .ant-menu-item{
                    font-size: 1.2rem;
                    font-weight: 600;
                    a{
                        color: @white;
                    }
                    .ant-menu-title-content{
                        color: @white;   
                    }
                    &.ant-menu-item-selected{
                        background: none;
                        a{
                            color: @white;
                        }
                        .ant-menu-title-content{
                            color: @white;   
                        }
                    }
                }
            }
            .footer-links{
                justify-content: space-between;
                display: flex;
                align-items: center;
                .ant-btn{
                    .icon{
                        fill: @white;
                    }
                }
            }
        }
    }
}
@import "../../assets/less/variables.less";

.terms-modal{
    iframe {
        width: 100%;
        height: 480px;
    }
    .tc-check{
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        .ant-checkbox-wrapper{
            color: @white;
            .ant-checkbox-inner{
                border-color: @white;
                background: none;
            }
        }
    }
    .pdf-view{
        background: @white;
        padding: 20px;
        color: @black;
        max-height: calc(100vh - 320px);
        overflow-y: auto;
        h4{
            color: @black;
        }
        p{
            font-size: @font-size-base;
        }
        .border-box{
            border: 1px solid @black;
            padding: 10px;
            color: @error-color;
            font-weight: 500;
        }
        .orderlist{
            padding-left: 0;
        }
        h5{
            font-size: 15px;
        }
        ol, ul{
            li{
                margin-bottom: 15px;
            }
        }
    }
    .unstyled{
        list-style-type: none;
        padding-left: 0;
    }
}
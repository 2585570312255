/*
*
Cswap
*                                                           
*/ 
@import './variables.less';

html, body{
    min-height: 100vh;
    font-family: @font-family !important;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased !important;
    font-feature-settings: normal !important;
    font-variant:normal !important;
    font-size: @font-size-base + 2;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    background-attachment: fixed;
    color: @white;
    background: @secondary-color;
}
main{
  position: relative;
  font-family: @font-family !important;
}
a{
  text-decoration: none;
}
.icon {
  width: 16px;
  height: auto;
  fill: #000;
  transition: fill 0.3s;
  &.icon-white{
    fill: #fff;
  }
}

#root{
  overflow-x: hidden;
}

.anchor-tag{
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1{
  font-size: 2rem;
  font-weight: bold;
  color: @white;
}

h2{
  font-weight: bold;
  color: @white;
}

h3{
  font-weight: 600;
  color: @white;
}

h4{
  color: @white;
}

p{
  font-size: @font-size-base + 2;
  margin-top: 0;
}

.ant-btn{
  height: 39px;
  line-height: 39px;
  padding: 0 25px;
  font-weight: 700;
  letter-spacing: 2px;
  &.ant-btn-link{
    height: auto;
    line-height: initial;
    font-weight: 300;
    font-size: 1.063rem;
    letter-spacing: 0;
    padding: 0;
    white-space: inherit;
    text-align: left;
  }
  &.with-icon{
    position: relative;
    .icon{
      position: absolute;
      right: 0;
      fill: @white;
      top: 48%;
      transform: translateY(-50%);
    }
  }
  &.ant-btn-primary{
    color: @white !important;
    border-radius: 39px;
    text-transform: uppercase;
    background: @primary-color;
    border-color: @primary-color;
    &:hover, &:focus{
      background: @primary-color;
      border-color: @primary-color;
      opacity: 0.9;
    }
  }
  &.ant-btn-secondary{
    background: none;
    border: 0;
    color: #00234B;
    border-radius: 39px;
    text-transform: uppercase;
    background: @white;
    &:hover, &:focus{
      background: darken(@white, 0.5);
      &::after{
        background: #15305A;
      }
    }
  }
  &.ant-btn-lg{
    height: 68px;
    line-height: 68px;
    padding: 0 3rem;
  }
  &[disabled]{
    opacity: 0.5;
    pointer-events: none;
  }
}

.ant-radio-wrapper{
  font-size: 13px;
  font-weight: 600;
  .ant-radio{
    .ant-radio-inner{
      border-color: @black;
    }
    &.ant-radio-checked{
      .ant-radio-inner{
        &::after{
          background: #DD4A4A;
        }
      }
    }
  }
}

.ant-select{
  &:not(.ant-select-customize-input){
    .ant-select-selector{
      height: 40px;
      line-height: 40px;
      background-color: @card-bg;
      color: @black;
      border: 1px solid @black;
      border-radius: 6px;
      &:hover, &:focus{
        border: 1px solid @black;
      }
      input{
        height: 40px !important;
        line-height: 100%;
      }
      .ant-select-selection-item{
        line-height: 38px;
      }
      .ant-select-selection-placeholder{
        line-height: 38px;
        color: @black;
      }
    }
    .ant-select-arrow{
      .icon{
        width: 13px;
      }
    }
  }
}

.ant-layout{
  background: none;
  .ant-layout-header{
    background: none;
    box-shadow: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: 0;
  }
  .ant-layout-content{
    padding: 0;
    @media screen and (max-width:991px) {
      padding: 0;
    }
  }
  .footer-bottom{
    padding: 1.4rem 0;
    background: radial-gradient(44.46% 968.33% at 51.07% 59.17%, #35030D 0%, #060234 100%);
  }
}

.body-image{
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
}

.headings{
  text-align: center;
  p{
    color: #D7CFCFCC;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  h1{
    font-size: 3.75rem;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1.1;
    @media screen and (max-width:991px) {
      font-size: 2.7rem;
    }
  }
  .divider{
    width: 170px;
    height: 4px;
    background: @gradient-bg;
    display: inline-block;
  }
  h4{
    font-weight: 400;
    font-size: 25px;
    margin-top: 0.8rem;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(@secondary-color, 0.3);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: @primary-color; 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(@primary-color, 0.8); 
}

.ant-modal{
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content{
    background: @secondary-color;
    color: @white;
    border: 1px solid rgba(@white, 0.2);
    border-radius: 15px;
    .ant-modal-header{
      background: none;
      margin-bottom: 15px;
      .ant-modal-title{
        color: @white;
        font-size: 20px;
      }
    }
  }
}